<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0 misc-wrapper">
        <b-link class="brand-logo">
          <b-img
            :src="appLogoImage"
            class="logo-content"
            alt="logo"
          />
          <h2 class="brand-text text-primary mb-0">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Forgot Password?
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your password
        </b-card-text>

        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div class="alert-body">
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="saya@email.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BImg, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BImg,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      userEmail: '',
      // validation
      required,
      email,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          document.getElementById('loading-bg').style.display = 'block'
          // this.$router.push({ name: 'auth-reset-password-v1' })
          this.$http.post('/forgot', {
            email: this.userEmail,
          })
            .catch(error => {
              document.getElementById('loading-bg').style.display = 'none'
              this.alert = true
              this.alertDetail = {
                variant: 'danger',
                title: 'Error Found',
                text: (error.response) ? error.response.data.message : error,
              }
            })
            .then(response => {
              document.getElementById('loading-bg').style.display = 'none'
              if (response.status === 200) {
                this.alert = true
                this.alertDetail = {
                  variant: 'success',
                  title: 'Info',
                  text: response.data.status,
                }
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
